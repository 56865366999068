<template>
  <div class="container">
    <div class="row orderItem tw-relative top_bar">

      <div class="col-lg-6 col-sm-6 col-12">

        <div
          class="orderItem__title orderItem__colour"

        >
            <span class="tw-inline-block tw-flex tw-items-center tw-space-x-2">
          <span class="top_bar__left_title">
            <svg-icon name="arrow-left" class="mr-2 top_bar__back_btn"
                               @click="$router.back()"/>{{
              getFullName(customer)
            }} <span class="top_bar__total">({{ customer.type }}) </span>
            </span>
            </span>
        </div>

      </div>

      <div class="col-lg-6 col-sm-6 col-12 ">
        <div
          class="orderItem__title orderItem__colour flex-row-reverse top_bar__total"
        >
          Total value : {{ customer.total_value || 0.0 }}
        </div>
      </div>

    </div>

    <div class="row mt-2  details_row" style="background-color:#B5B5B5">
      <div class="col-12 px-0">
        <div
          class=" tw-relative"
        >
          <div
            class="orderItem__title orderItem__colour py-2 mx-2"
          >
        <span class="details_row__left_address">
          <span>
            Address : {{ getCustomerFullAddress(customer) }}
          </span>
          <br>
          <span>
            Phone : {{ customer.phone }}
          </span>
        </span>
          </div>

        </div>
      </div>
      <div class="col-12"
      >
        <div class="d-flex d-flex justify-content-between ">
          <div
            class="orderItem__title orderItem__colour d-flex flex-column"
          >
            <h5 class="text-right">PRICE LISTS</h5>

            <div class="d-flex flex-row">
              <div class="p-2">Flex item 1</div>
              <div class="p-2">Flex item 2</div>
              <div class="p-2">Flex item 3</div>
              <div class="p-2">Flex item 3</div>
            </div>

          </div>

          <div class="mt-auto pb-2 details_row__created"> Created at : {{
              $filters.formatDate(customer.created_at)
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">

      <SingleOrderTileComponent class="tile"
                                :key="order.id"
                                v-for="(order,index) in customerOrders"
                                :order="order"
      />
      <div v-if="pagination.has_more_pages" class="col-12 load_more" @click="loadMore()">
        Load more...
      </div>
    </div>
    <div class="row mt-2" v-if="customerOrders.length === 0">
      <div class="col-12 no_data">
        No customers to display.
      </div>

    </div>
  </div>
</template>

<script>
import CustomerAPI from '@/routes/Customer/CustomerAPI.js'
import SingleOrderTileComponent from '/src/components/customer/SingleOrderTileComponent'
import GlobalMixins from "../../Mixins/GlobalMixins";

export default {
  name: "CustomerShow",
  components: {
    SingleOrderTileComponent
  },
  mixins : [GlobalMixins],
  data() {
    return {
      customer: {},
      customerOrders: [],
      pagination: {},
    }
  },
  async mounted() {
    this.startLoader()
    await this.getCustomerDetails()
    await this.getCustomerOrderDetails()
    this.stopLoader()
  },
  methods: {
    async getCustomerDetails() {
      this.customer = (await CustomerAPI.getDealerSingleCustomer(this.$route.params.id)).data.data
    },
    async getCustomerOrderDetails() {
      let response = (await CustomerAPI.dealerSingleCustomerOrders(this.$route.params.id)).data
      this.customerOrders = response.data
      this.pagination = response.pagination
    },
    async loadMore() {
      this.startLoader()
      let response = (await CustomerAPI.dealerSingleCustomerOrders(this.$route.params.id, this.pagination.current_page + 1)).data
      response.data.forEach((order) => {
        this.customerOrders.push(order)
      })
      this.pagination = response.pagination
      this.stopLoader()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/utils/_vars.scss";

.top_bar {
  background-color: #0E3753;
  padding: 10px 0;

  &__total {
    font-size: $defSize !important;
    text-transform: none !important;
  }

  &__left_title {
    text-transform: capitalize !important;
  }

  &__back_btn {
    cursor: pointer !important;
  }
}

.top_bar.orderItem__title {
  text-transform: capitalize !important;
}

.orderItem {
  margin-bottom: 0.5rem;
}

.details_row {
  &__left_address, &__created {
    text-transform: capitalize !important;
    font-size: $h5 !important;
    color: $navy;
    font-weight: 100;
  }
}

.tile:nth-child(2n+1) {
  padding-left: 0

}

.tile:nth-child(3n) {
  padding-right: 0

}

.load_more {
  text-align: center;
  border: 2px #55b494 solid;
  font-size: 1.2em;
  margin-bottom: 25px;
  padding: 10px 0;
  cursor: pointer;
}

.no_data {
  text-align: center;
  border: 2px $navy solid;
  font-size: 1.2em;
  margin-bottom: 25px;
  padding: 40px 0;
}
</style>
