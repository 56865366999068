<template>
  <div class="col-lg-4 col-sm-6 col-12">
    <div
      :style="{border: '1px solid black'}"
      class="orderItem tw-relative"
    >
      <h2
        class="orderItem__title orderItem__colour"
        style="background-color:#B5B5B5"
      >
        <span class="tw-inline-block tw-flex tw-items-center tw-space-x-2">
          <span>
            {{ order.current_status.status.name }}  #{{ order.id }}
          </span>
        </span>
        <!--        <span
                  class="orderItem__iconRight"
                >
                  <span class="tw-inline-block tw-h-5">
                    <svg-icon name="pen"/>
                  </span>
                </span>-->
      </h2>

      <div class="orderItem__boxInfo tw-grid  tw-space-x-2 tw-font-helvetica">
        <div class="tw-space-y-0">
          <ul
            class="pl-1"
            style="list-style: inside;"
          >
            <li>
              Date : {{ order.created_date }}
            </li>
            <li>
              Order Date : {{ order.order_date }}
            </li>
            <li>Reference : {{ order.dealer_reference ? order.dealer_reference : 'N/A' }}</li>
            <li>Order value: {{ getValueInAU(order.total) }}</li>
            <li>{{ order.current_status.description }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalMixins from "../../Mixins/GlobalMixins";
export default {
  name: "SingleOrderTileComponent",
  mixins : [GlobalMixins],
  props: {
    order: {
      required: true,
      type: Object,
    }
  }
}
</script>

<style scoped>

</style>
